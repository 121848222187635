<script setup lang="ts">
import { useRoute, useRouter } from 'vue-router';
import { isInsideMobileApp, useBackClick } from '@/app/bridge';
import { NAV_DASHBOARD, NAV_ROOT, nfcStaticPages } from '@/shared/lib';
import BackButton from '../BackButton.vue';
import QueueCounter from '../QueueCounter.vue';

interface Props {
  text: string;
  description?: string;
  goBack?: boolean;
  className?: string;
  transparent?: boolean;
  onHandleBack?: () => void;
}

const { text, goBack, className, transparent, description, onHandleBack } = defineProps<Props>();

const route = useRoute();
const router = useRouter();

const handleClick = () => {
  if (onHandleBack) {
    onHandleBack();
  } else {
    const prevRoute = (router.options.history.state.back || '').toString();
    if (nfcStaticPages.includes(prevRoute)) {
      router.push(NAV_DASHBOARD);
    } else if (route.path === NAV_DASHBOARD || route.path === NAV_ROOT) {
      return; // Не переходить назад из дашборда или корневой страницы (актуально для МП при отображаемой кнопки Назад)
    } else {
      router.back();
    }
  }
};

useBackClick(handleClick);
</script>

<template>
  <div
    :class="{
      [$style.heading]: true,
      [className as string]: className,
      [$style.transparent]: transparent,
    }"
  >
    <div :class="$style.wrap">
      <BackButton v-if="!isInsideMobileApp && goBack" :onClick="handleClick" />
      <a-typography-title :level="4" :class="$style.name">
        {{ text }}
        <div :class="$style.description" v-if="description">
          {{ description }}
        </div>
      </a-typography-title>
      <div :class="$style.slotContainer">
        <QueueCounter />
        <slot :class="$style.slot"></slot>
      </div>
    </div>
  </div>
</template>

<style lang="scss" module>
.heading {
  padding: 0 12px;
  background-color: var(--color-primary, #ef7f1a);
  position: sticky;
  top: 0;
  z-index: 1;
}

.heading.transparent {
  background-color: transparent;

  .name,
  .back {
    color: var(--color-text, #333333);
  }
}

.wrap {
  display: flex;
  align-items: center;
  gap: 12px;
  min-height: 48px;
  position: relative;

  button {
    color: var(--color-white, #fff);
    font-weight: 600;
  }

  .name {
    color: var(--color-white, #fff);
    font-size: 17px;
    text-align: center;
    margin: 0 auto;
    max-width: 213px;
    word-break: unset;
  }

  .description {
    color: var(--color-text, #333333);
    font-size: 12px;
    font-weight: 400;
  }
}

.slotContainer {
  color: var(--color-white, #fff);
  display: flex;
  align-items: center;
  gap: 4px;
  min-width: 24px;
}

.slotContainer:empty {
  display: none;
}

.slot {
  color: var(--color-white, #fff);
  width: 24px;
  height: 24px;
}
</style>
