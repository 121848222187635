<script lang="ts" setup>
import { close } from '@orta/bridge';
import { isInsideMobileApp } from '@/app/bridge';
import { Greetings } from '@/shared/assets/svg';
import { BottomButtons } from '@/shared/ui';
import styles from './ShiftFinished.module.scss';

const handleButtonClose = async () => await close();
</script>

<template>
  <div :class="styles.page">
    <Greetings :class="styles.icon" />
    <a-typography-title :level="2" :class="styles.title" :style="{ margin: 0 }"
      >Спасибо!</a-typography-title
    >
    <a-typography-text :class="styles.text">Ваша смена завершена</a-typography-text>
  </div>

  <BottomButtons :noPanel="true" @click="isInsideMobileApp ? handleButtonClose : undefined">
    <a-button type="primary" size="large">Выйти из приложения</a-button>
  </BottomButtons>
</template>
