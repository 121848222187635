import axios from 'axios';
import { convertResponseData } from 'fmptools';
import { EXTRA_DELIMITER } from '../lib';
import { useAlertStore } from '../model/useAlertStore';

export const httpFmpClient = axios.create({
  adapter: 'fetch',
  baseURL: `${import.meta.env.VITE_FMP_BACKEND}/api/v1/`,
  timeout: 600000,
  headers: {},
  validateStatus: status => status !== 401, //this is provide to return any Status Code except authorization
});

export const httpBackendClient = axios.create({
  adapter: 'fetch',
  baseURL: `${import.meta.env.VITE_ERG_BACKEND}/api/v1/`,
  timeout: 600000,
  headers: {},
  validateStatus: status => status !== 401,
});

export const schema = {};
export const setSchema = (newSchema: Record<string, any>) => Object.assign(schema, newSchema);
export const getSchema = () => schema;

httpFmpClient.interceptors.response.use(
  response => {
    const url = response.request?.url || response.request?.responseURL;
    const isRpcResource = url.includes('/rpc/');
    if (isRpcResource) {
      const resource = url.split('/').at(-2);
      response.data = convertResponseData(response.data, schema, resource);
    }
    return response;
  },
  error => {
    const { setAlert } = useAlertStore();
    const errorMessage = [`Произошла ошибка в запросе ${error.response.config.url}`];

    if (error.response.data?.description) {
      errorMessage.push(error.response.data.description);
    }
    setAlert({
      type: 'error',
      message: errorMessage.join(EXTRA_DELIMITER),
    });
    return Promise.reject(error.response.data);
  },
);

httpFmpClient.defaults.validateStatus = status => {
  return [200, 201, 402].indexOf(status) !== -1;
};
