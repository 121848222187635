import { createRouter, createWebHistory } from 'vue-router';
import Brigades from '@/pages/brigades';
import DashboardPage from '@/pages/dashboard';
import DefectCreateEquipment from '@/pages/defect-create-equipment';
import DefectCreateOperation from '@/pages/defect-create-operation';
import DefectDetail from '@/pages/defect-detail';
import DefectHistory from '@/pages/defect-history';
import Defects from '@/pages/defects';
import DocumentDetail from '@/pages/document-detail/ui/DocumentDetail.vue';
import EquipmentDetail from '@/pages/equipment-detail';
import EquipmentLines from '@/pages/equipment-lines';
import EquipmentSearch from '@/pages/equipment-search';
import Inspection from '@/pages/inspection';
import MaintenanceOrRepair from '@/pages/maintenance-or-repair';
import Nfc from '@/pages/nfc';
import NfcList from '@/pages/nfc-list';
import OperationDetail from '@/pages/operation-detail';
import Operations from '@/pages/operations';
import OperationsMeasure from '@/pages/operations-measure/ui/OperationsMeasure.vue';
import OperationsSlider from '@/pages/operations-slider';
import OperationsTag from '@/pages/operations-tag';
import OperationsTagSlider from '@/pages/operations-tag-slider';
import OrderHistoryDetail from '@/pages/order-history-detail';
import Reassignment from '@/pages/reassignment';
import Reserves from '@/pages/reserves';
import ReservesSearch from '@/pages/reserves-search';
import Sectors from '@/pages/sectors';
import ShiftPage from '@/pages/shift';
import ShiftFinish from '@/pages/shift-finish';
import ShiftFinished from '@/pages/shift-finished';
import ShiftOrder from '@/pages/shift-order';
import Unit from '@/pages/unit';
import {
  NAV_BRIGADES,
  NAV_DASHBOARD,
  NAV_DEFECTS,
  NAV_DEFECTS_HISTORY,
  NAV_DEFECT_CREATE_FROM_EQUIPMENT,
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_DEFECT_CREATE_FROM_OPERATION,
  NAV_DEFECT_DETAIL,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_DOCUMENT_DETAIL,
  NAV_EQUIPMENT_LINES,
  NAV_EQUIPMENT_LINES_CREATE_DEFECT,
  NAV_EQUIPMENT_SEARCH,
  NAV_EQUIPMENT_SEARCH_NO_NFC,
  NAV_INSPECTION_DETAIL,
  NAV_MAINTENANCE_OR_REPAIR,
  NAV_MATERIAL_SEARCH,
  NAV_NFC,
  NAV_NFC_CREATE_DEFECT,
  NAV_NFC_CREATE_ORDER,
  NAV_NFC_LIST,
  NAV_NFC_ORDER,
  NAV_OPERATIONS,
  NAV_OPERATIONS_MEASURE,
  NAV_OPERATIONS_SLIDER,
  NAV_OPERATIONS_TAG,
  NAV_OPERATIONS_TAG_SLIDER,
  NAV_OPERATION_DETAIL,
  NAV_ORDER_CREATE,
  NAV_ORDER_DEFECT_CREATE,
  NAV_ORDER_DEFECT_DETAIL,
  NAV_ORDER_DOCUMENT_DETAIL,
  NAV_ORDER_HISTORY_DETAIL,
  NAV_ORDER_IDLE_CREATE,
  NAV_REASSIGNMENT,
  NAV_RESERVES,
  NAV_RESERVES_SEARCH,
  NAV_ROOT,
  NAV_SECTORS,
  NAV_SHIFT_FINISH,
  NAV_SHIFT_FINISHED,
  NAV_SHIFT_ORDER,
  NAV_UNITS,
  NOT_FOUND,
} from '@/shared/lib/constants';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: NAV_ROOT,
      name: NAV_ROOT,
      component: ShiftPage,
    },
    {
      path: NAV_DASHBOARD,
      name: NAV_DASHBOARD,
      component: DashboardPage,
    },

    {
      path: `${NAV_SHIFT_ORDER}/:orderId`,
      name: NAV_SHIFT_ORDER,
      component: ShiftOrder,
    },
    {
      path: NAV_SHIFT_FINISH,
      name: NAV_SHIFT_FINISH,
      component: ShiftFinish,
    },

    {
      path: NAV_SHIFT_FINISHED,
      name: NAV_SHIFT_FINISHED,
      component: ShiftFinished,
    },
    {
      path: `${NAV_INSPECTION_DETAIL}/:orderId`,
      name: NAV_INSPECTION_DETAIL,
      component: Inspection,
    },
    {
      path: `${NAV_MAINTENANCE_OR_REPAIR}/:orderId`,
      name: NAV_MAINTENANCE_OR_REPAIR,
      component: MaintenanceOrRepair,
    },
    {
      path: `${NAV_DEFECTS}/:sectorId`,
      name: NAV_DEFECTS,
      component: Defects,
    },
    {
      path: `${NAV_DEFECT_DETAIL}/:id`,
      name: NAV_DEFECT_DETAIL,
      component: DefectDetail,
    },
    {
      path: `${NAV_ORDER_DEFECT_DETAIL}/:id`,
      name: NAV_ORDER_DEFECT_DETAIL,
      component: DefectDetail,
    },
    {
      path: NAV_SECTORS,
      name: NAV_SECTORS,
      component: Sectors,
    },
    {
      path: `${NAV_DEFECT_CREATE_FROM_EQUIPMENT}/:equipmentId`,
      name: NAV_DEFECT_CREATE_FROM_EQUIPMENT,
      component: DefectCreateEquipment,
    },
    {
      path: `${NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL}/:equipmentId`,
      name: NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
      component: DefectCreateEquipment,
    },

    {
      path: `${NAV_DEFECT_CREATE_FROM_OPERATION}/:orderId/:operationId`,
      name: NAV_DEFECT_CREATE_FROM_OPERATION,
      component: DefectCreateOperation,
    },
    {
      path: `${NAV_DEFECTS_HISTORY}/:unitId`,
      name: NAV_DEFECTS_HISTORY,
      component: DefectHistory,
    },
    {
      path: `${NAV_EQUIPMENT_DETAIL}/:equipmentId`,
      name: NAV_EQUIPMENT_DETAIL,
      component: EquipmentDetail,
    },

    {
      path: `${NAV_UNITS}/:id`,
      name: NAV_UNITS,
      component: Unit,
    },
    {
      path: NAV_EQUIPMENT_SEARCH_NO_NFC,
      name: NAV_EQUIPMENT_SEARCH_NO_NFC,
      component: EquipmentSearch,
    },
    {
      path: NAV_EQUIPMENT_SEARCH,
      name: NAV_EQUIPMENT_SEARCH,
      component: EquipmentSearch,
    },
    {
      path: `${NAV_EQUIPMENT_LINES}/:workshopId/:areaId`,
      name: NAV_EQUIPMENT_LINES,
      component: EquipmentLines,
    },
    {
      path: `${NAV_EQUIPMENT_LINES_CREATE_DEFECT}/:workshopId/:areaId`,
      name: NAV_EQUIPMENT_LINES_CREATE_DEFECT,
      component: EquipmentLines,
    },
    {
      path: NOT_FOUND,
      name: NOT_FOUND,
      component: () => import('@/pages/404'),
    },
    {
      path: `${NAV_ORDER_DOCUMENT_DETAIL}/:id`,
      name: NAV_ORDER_DOCUMENT_DETAIL,
      component: DocumentDetail,
    },
    {
      path: `${NAV_EQUIPMENT_DOCUMENT_DETAIL}/:id`,
      name: NAV_EQUIPMENT_DOCUMENT_DETAIL,
      component: DocumentDetail,
    },
    {
      path: `${NAV_OPERATIONS}/:orderId`,
      name: NAV_OPERATIONS,
      component: Operations,
    },
    {
      path: `${NAV_OPERATIONS_TAG}/:orderId/:tagId`,
      name: NAV_OPERATIONS_TAG,
      component: OperationsTag,
    },
    {
      path: `${NAV_OPERATIONS_TAG_SLIDER}/:orderId/:tagId/:operationId`,
      name: `${NAV_OPERATIONS_TAG_SLIDER}/:orderId`,
      component: OperationsTagSlider,
    },
    {
      path: `${NAV_OPERATIONS_SLIDER}/:orderId/:operationId`, //айди операции только в связке aufnr и vornr нужен для определения initialSlide slide
      name: NAV_OPERATIONS_SLIDER,
      component: OperationsSlider,
    },
    {
      path: `${NAV_OPERATION_DETAIL}/:orderId/:operationId`, //айди операции только в связке aufnr и vornr
      name: NAV_OPERATION_DETAIL,
      component: OperationDetail,
    },
    {
      path: `${NAV_OPERATIONS_MEASURE}/:orderId`,
      name: NAV_OPERATIONS_MEASURE,
      component: OperationsMeasure,
    },
    {
      path: NAV_NFC,
      name: NAV_NFC,
      component: Nfc,
    },
    {
      path: `${NAV_NFC_ORDER}/:orderId`,
      name: NAV_NFC_ORDER,
      component: Nfc,
    },
    {
      path: `${NAV_NFC_ORDER}/:orderId/:tagId`,
      name: `${NAV_NFC_ORDER}/:orderId/:tagId`,
      component: Nfc,
    },
    {
      path: `${NAV_ORDER_CREATE}/:equipmentId`,
      name: NAV_ORDER_CREATE,
      component: () => import('@/pages/create-order'),
    },
    {
      path: `${NAV_ORDER_DEFECT_CREATE}/:defectId`,
      name: NAV_ORDER_DEFECT_CREATE,
      component: () => import('@/pages/create-order'),
    },
    {
      path: `${NAV_ORDER_IDLE_CREATE}/:idleId`,
      name: NAV_ORDER_IDLE_CREATE,
      component: () => import('@/pages/create-order'),
    },
    {
      path: NAV_MATERIAL_SEARCH,
      name: NAV_MATERIAL_SEARCH,
      component: () => import('@/pages/material-search'),
    },
    {
      path: NAV_NFC_CREATE_DEFECT,
      name: NAV_NFC_CREATE_DEFECT,
      component: Nfc,
    },
    {
      path: NAV_NFC_CREATE_ORDER,
      name: NAV_NFC_CREATE_ORDER,
      component: () => import('@/pages/nfc'),
    },
    {
      path: `${NAV_NFC_LIST}/:orderId`,
      name: NAV_NFC_LIST,
      component: NfcList,
    },
    {
      path: `${NAV_REASSIGNMENT}/:orderId`,
      name: NAV_REASSIGNMENT,
      component: Reassignment,
    },
    {
      path: NAV_RESERVES,
      name: NAV_RESERVES,
      component: Reserves,
    },
    {
      path: NAV_RESERVES_SEARCH,
      name: NAV_RESERVES_SEARCH,
      component: ReservesSearch,
    },
    {
      path: `${NAV_ORDER_HISTORY_DETAIL}/:orderId`,
      name: NAV_ORDER_HISTORY_DETAIL,
      component: OrderHistoryDetail,
    },
    {
      path: NAV_BRIGADES,
      name: NAV_BRIGADES,
      component: Brigades,
    },
  ],
  scrollBehavior(to, from, savedPosition) {
    if (
      savedPosition &&
      ((to.name === NAV_EQUIPMENT_LINES && from.name === NAV_EQUIPMENT_DETAIL) ||
        (to.name === NAV_DEFECTS && from.name === NAV_DEFECT_DETAIL))
    ) {
      return savedPosition;
    } else {
      return {
        left: 0,
        top: 0,
      };
    }
  },
});

export default router;
