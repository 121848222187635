<script setup lang="ts">
import { computed, ref, watchEffect } from 'vue';
import { onClickOutside, useSwipe } from '@vueuse/core';
import { storeToRefs } from 'pinia';
import { HomeIcon, MenuIcon, SquareRoundedPlusIcon } from '@/shared/assets/svg';
import {
  createNewEntitiesMenuItems,
  employeeMenuItems,
  NAV_DASHBOARD,
  NAV_NFC_CREATE_DEFECT,
  NAV_SHIFT_FINISH,
  repairerMenuItems,
  repairerSupervisorMenuItems, STORAGE_DATE_FINISHED_SHIFT,
  technologistMenuItems,
  technologistSupervisorMenuItems,
} from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { useStorage } from '@/shared/hooks';
import CreateNewEntitiesMenu from '@/shared/ui/CreateNewEntitiesMenu/CreateNewEntitiesMenu.vue';
import { Menu } from '@/shared/ui/Menu';
import styles from './BottomPanel.module.scss';
import { OrderTypesName, useGetOrderByType } from '@/entities/Orders';

const {
  userIsRepairer,
  userIsSupervisor,
  userIsTechnologist,
  userIsEmployee,
  userIsRepairSupervisor,
  userIsTechnologSupervisor,
  profile,
} = storeToRefs(useUserProfileStore());
const { ordersByType } = useGetOrderByType();
const { getItem } = useStorage();
const shiftFinishedToday = ref(false);

function getMenuItems() {
  if (userIsTechnologist.value) {
    return technologistMenuItems;
  } else if (userIsRepairer.value) {
    return repairerMenuItems;
  } else if (userIsRepairSupervisor.value) {
    return repairerSupervisorMenuItems;
  } else if (userIsTechnologSupervisor.value) {
    return technologistSupervisorMenuItems;
  } else if (userIsEmployee.value) {
    return employeeMenuItems;
  } else {
    return [];
  }
}

const getLastDateShiftFinished = async () => {
  const dateFinishedShift = await getItem(STORAGE_DATE_FINISHED_SHIFT);

  shiftFinishedToday.value =
    new Date(profile.value.shift_end_date || '').getDate() ===
    new Date(dateFinishedShift || '').getDate();
};

const shiftFinishDisabled = computed(() => {
  return !ordersByType.value?.[OrderTypesName.ALL].length || shiftFinishedToday.value;
});

watchEffect(() => {
  getLastDateShiftFinished();
});

const menuItems = computed(() => getMenuItems().map((item) => ({
  ...item,
  disabled: Boolean(item.path === NAV_SHIFT_FINISH && shiftFinishDisabled.value),
})));

const target = ref<HTMLElement | null>(null);
const bodyHeight = computed(() => document.body.offsetHeight);

const bottom = ref('-200%');
const display = ref('none');
const opacity = ref(1);

const newItemsBottom = ref('-200%');

function showOverlay() {
  opacity.value = 1;
  display.value = 'block';
}

function hideOverlay() {
  opacity.value = 0;
  display.value = 'none';
}

function showMenu() {
  bottom.value = '12px';
  showOverlay();
}

function hideMenu() {
  bottom.value = '-200%';
  hideOverlay();
}

function showNewItemsMenu() {
  newItemsBottom.value = '80px';
  showOverlay();
}

function hideNewItemsMenu() {
  newItemsBottom.value = '-200%';
  hideOverlay();
}

onClickOutside(target, () => {
  hideMenu();
  hideNewItemsMenu();
});

const { isSwiping, lengthY } = useSwipe(target, {
  passive: true,
  onSwipe() {
    if (bodyHeight.value) {
      if (lengthY.value < 0) {
        const length = Math.abs(lengthY.value);
        bottom.value = `-${length}px`;
        opacity.value = 1.1 - length / bodyHeight.value;
      } else {
        showMenu();
      }
    }
  },
  onSwipeEnd() {
    if (
      lengthY.value < 0 &&
      bodyHeight.value &&
      Math.abs(lengthY.value) / bodyHeight.value >= 0.3
    ) {
      hideMenu();
    } else {
      showMenu();
    }
  },
});
</script>

<template>
  <nav :class="styles.panel">
    <ul :class="styles.list">
      <li :class="styles.item">
        <router-link :active-class="styles.active" :class="styles.link" :to="NAV_DASHBOARD">
          <HomeIcon />
        </router-link>
      </li>
      <li :class="styles.item">
        <button
          v-if="userIsSupervisor"
          :class="styles.button"
          type="button"
          @click="showNewItemsMenu"
        >
          <SquareRoundedPlusIcon />
        </button>
        <router-link
          v-else
          :active-class="styles.active"
          :class="styles.link"
          :to="NAV_NFC_CREATE_DEFECT"
          ><SquareRoundedPlusIcon />
        </router-link>
      </li>
      <li :class="styles.item">
        <button :class="styles.button" type="button" @click="showMenu">
          <MenuIcon />
        </button>
      </li>
    </ul>
    <div :class="styles.overlay" :style="{ opacity, display }" />
    <CreateNewEntitiesMenu
      :class="{ [styles.newEntitiesMenu]: true, [styles.animated]: !isSwiping }"
      :style="{ bottom: newItemsBottom }"
      :items="createNewEntitiesMenuItems"
      :onLinkClick="hideNewItemsMenu"
    />
    <Menu
      ref="target"
      :class="{ [styles.menu]: true, [styles.animated]: !isSwiping }"
      :style="{ bottom }"
      :items="menuItems"
      :onLinkClick="hideMenu"
    />
  </nav>
</template>
