<script lang="ts" setup>
import { computed, ref, watchEffect } from 'vue';
import { useRouter } from 'vue-router';
import { storeToRefs } from 'pinia';
import BrigadesOrdersWidget from '@/widgets/BrigadesOrdersWidget/ui/BrigadesOrdersWidget.vue';
import { OrdersByTeamsWidget } from '@/widgets/OrdersByTeamsWidget';
import {
  DefectsPanel,
  NoOrders,
  OrderStatus,
  OrderTypesName,
  OrdersList,
  TasksPanel,
  useGetOrderByType,
} from '@/entities/Orders';
import { useStorage } from '@/shared/hooks';
import {
  NAV_DASHBOARD,
  NAV_SHIFT_FINISHED,
  STORAGE_DATE_FINISHED_SHIFT,
} from '@/shared/lib/constants';
import { useUserProfileStore } from '@/shared/model';
import { InformationDialog, PageHeading } from '@/shared/ui';
import { BottomButtons } from '@/shared/ui';
import styles from './ShiftFinish.module.scss';

const router = useRouter();
const { ordersByType, ordersFetchError } = useGetOrderByType();
const { profile, userIsRepairer, userIsSupervisor } = storeToRefs(useUserProfileStore());
const { getItem, setItem } = useStorage();
const infoModalOpen = ref(false);
const shiftFinishedToday = ref(false);
const finishedStatuses = [
  OrderStatus.COMPLETED,
  OrderStatus.CLOSED,
  OrderStatus.CLOSED_WITH_ERRORS,
  OrderStatus.CANCELED,
];

const getLastDateShiftFinished = async () => {
  const dateFinishedShift = await getItem(STORAGE_DATE_FINISHED_SHIFT);

  shiftFinishedToday.value =
    new Date(profile.value.shift_end_date || '').getDate() ===
    new Date(dateFinishedShift || '').getDate();
};

const finishShiftHandler = async () => {
  if (userIsRepairer.value) {
    finishedStatuses.push(OrderStatus.PENDING);
  }
  const allOrdersFinished = ordersByType.value?.[OrderTypesName.ALL].every(order =>
    finishedStatuses.includes(order.STTXT),
  );
  if (allOrdersFinished) {
    await setItem(STORAGE_DATE_FINISHED_SHIFT, profile.value.shift_end_date || '');
    router.push({
      name: NAV_SHIFT_FINISHED,
    });
  } else {
    infoModalOpen.value = true;
  }
};

const handleBack = () => router.push(NAV_DASHBOARD);
const shiftFinishDisabled = computed(() => {
  return !ordersByType.value?.[OrderTypesName.ALL].length || shiftFinishedToday.value;
});

watchEffect(() => {
  getLastDateShiftFinished();
});
</script>

<template>
  <PageHeading text="Сдать смену" :goBack="true" :onHandleBack="handleBack" />
  <div :class="styles.page">
    <BrigadesOrdersWidget v-if="userIsSupervisor" :shiftView="true" />
    <TasksPanel v-else :shiftView="true" />
    <DefectsPanel v-if="userIsRepairer" />
    <NoOrders v-if="!ordersFetchError && !ordersByType?.[OrderTypesName.ALL].length" />
    <OrdersByTeamsWidget v-if="userIsSupervisor" :title="'Все заказы'" />
    <div v-if="!ordersFetchError && ordersByType?.[OrderTypesName.ALL].length && !userIsSupervisor">
      <div v-if="Boolean(ordersByType?.[OrderTypesName.INSPECTION].length)">
        <a-typography-title v-if="!userIsSupervisor" :level="4" :class="styles.title">
          Осмотры
        </a-typography-title>
        <OrdersList
          v-if="Boolean(ordersByType?.[OrderTypesName.INSPECTION].length)"
          :orders="ordersByType?.[OrderTypesName.INSPECTION]"
        />
      </div>
      <template v-if="userIsRepairer">
        <div v-if="Boolean(ordersByType?.[OrderTypesName.MAINTENANCE].length)">
          <a-typography-title :level="4" :class="styles.title">
            Техническое обслуживание
          </a-typography-title>
          <OrdersList :orders="ordersByType?.[OrderTypesName.MAINTENANCE]" />
        </div>
        <div v-if="Boolean(ordersByType?.[OrderTypesName.CURRENT_REPAIR].length)">
          <a-typography-title :level="4" :class="styles.title">Текущие ремонты</a-typography-title>
          <OrdersList :orders="ordersByType?.[OrderTypesName.CURRENT_REPAIR]" />
        </div>
      </template>
    </div>
  </div>
  <BottomButtons>
    <a-button
      @click="finishShiftHandler"
      type="primary"
      size="large"
      :disabled="shiftFinishDisabled"
    >
      Сдать смену
    </a-button>
  </BottomButtons>
  <InformationDialog
    v-if="infoModalOpen"
    text="Для сдачи смены необходимо закрыть все задания"
    :onClose="() => (infoModalOpen = false)"
  />
</template>
