<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { nfc } from '@orta/bridge';
import { storeToRefs } from 'pinia';
import { isInsideMobileApp } from '@/app/bridge';
import { useCreateConfirmStore } from '@/features/RejectionReasons';
import { useGetEquipList, useGetEquipmentInfo } from '@/entities/Equipments';
import { useGetEquipmentByParentNodes } from '@/entities/Equipments/hooks/useGetEquipmentByParentNodes';
import type { Equipment } from '@/entities/Equipments/interfaces';
import { getEquipmentChildAndChildrenChild } from '@/entities/Equipments/lib';
import {
  OrderStatus,
  useGetOrderByNfc,
  useGetOrderInfo,
  useOperationsByTag,
  useOrdersStore,
} from '@/entities/Orders';
import { checkOrderMaintenanceOrRepair } from '@/entities/Orders/lib';
import { NfcIcon } from '@/shared/assets/svg';
import { EquipmentStatuses } from '@/shared/interfaces';
import {
  NAV_DASHBOARD,
  NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
  NAV_EQUIPMENT_DETAIL,
  NAV_EQUIPMENT_SEARCH,
  NAV_EQUIPMENT_SEARCH_NO_NFC,
  NAV_MAINTENANCE_OR_REPAIR,
  NAV_NFC,
  NAV_NFC_CREATE_DEFECT,
  NAV_NFC_CREATE_ORDER,
  NAV_OPERATIONS,
  NAV_OPERATIONS_MEASURE,
  NAV_OPERATIONS_TAG,
  NAV_ORDER_CREATE,
} from '@/shared/lib';
import { useUserProfileStore } from '@/shared/model';
import { useAlertStore } from '@/shared/model/useAlertStore';
import { InformationDialog, PageHeading } from '@/shared/ui';
import { modalsResponseNFC, modalsResponseNFCTypes } from '../../lib';

const router = useRouter();
const route = useRoute();
const { orderId, tagId } = route.params;
const { userIsRepairer, userIsSupervisor } = storeToRefs(useUserProfileStore());
const { updateLocalOrderStatus } = useOrdersStore();
const { setAlert } = useAlertStore();
const { operations } = useOperationsByTag(tagId as string);
const { order } = useGetOrderInfo(orderId as string);
const createConfirmStore = useCreateConfirmStore();
const { equipmentList } = useGetEquipList();

const pageDescription = ref<string>(operations.value[0] ? operations.value[0].EQKTX : '');
const titlePage = ref(modalsResponseNFC[modalsResponseNFCTypes.IS_DEFAULT].title);
const textPage = ref(modalsResponseNFC[modalsResponseNFCTypes.IS_DEFAULT].text);
const titleModal = ref(modalsResponseNFC[modalsResponseNFCTypes.IS_DEFAULT].title);
const textModal = ref(modalsResponseNFC[modalsResponseNFCTypes.IS_DEFAULT].text);
const errorModalOpen = ref<boolean>(false);
const isSupportedNFC = ref<boolean>(true);
const statusNFC = ref<modalsResponseNFCTypes>(modalsResponseNFCTypes.IS_DEFAULT);
const openSettingsButton = ref<boolean>(false);
const isButtonLink = ref<boolean>(false);
const equipmentId = ref<Equipment | undefined>(undefined);

const handleModalClose = () => {
  errorModalOpen.value = false;

  if (!isSupportedNFC.value) {
    router.push({
      name: NAV_DASHBOARD,
    });
  } else {
    if (
      ![
        modalsResponseNFCTypes.IS_DISABLED,
        modalsResponseNFCTypes.IS_CANNOT_CREATE_DEFECT,
        modalsResponseNFCTypes.IS_CANNOT_CREATE_ORDER,
      ].includes(statusNFC.value)
    ) {
      methodsReaderNFC();
    } else {
      router.go(-1);
    }
  }
};
const handleSettings = async () => {
  await nfc.openSettings();
};
const handleStopReading = async () => {
  try {
    await nfc.stopReading();
  } catch {
    console.log('stopReading');
  }
};

const handleCancelOrder = async () => {
  if (order.value) {
    await createConfirmStore.fetchCreateConfirmAsync(
      order.value.AUFNR,
      operations.value?.length ? operations.value : order.value.EX_OPER_LIST,
      '0023',
      '',
      order.value?.timeBegin,
    );

    updateLocalOrderStatus(orderId as string, OrderStatus.CANCELED);
    router.push(NAV_DASHBOARD);
  }
};

const handleButtonTagNotReading = () => {
  handleModalOpen(modalsResponseNFCTypes.IS_NOT_READING_BUTTON);
  openSettingsButton.value = false;
  isButtonLink.value = true;
};

const handleButtonNoTag = () => {
  router.push({
    name: route.path === NAV_NFC_CREATE_DEFECT ? NAV_EQUIPMENT_SEARCH_NO_NFC : NAV_EQUIPMENT_SEARCH,
  });
};

const handleModalOpen = async (status: keyof typeof modalsResponseNFC) => {
  const { title, text } = modalsResponseNFC[status];
  const excludedStatusesTextPage = [
    modalsResponseNFCTypes.IS_NOT_READING_BUTTON,
    modalsResponseNFCTypes.IS_NOT_MATCHES_EQUIPS,
    modalsResponseNFCTypes.IS_NOT_MATCHES,
  ];

  if (!excludedStatusesTextPage.includes(status)) {
    titlePage.value = title;
    textPage.value = text;
  }

  titleModal.value = title;
  textModal.value = text;
  isButtonLink.value = false;
  statusNFC.value = status;

  if (status !== modalsResponseNFCTypes.IS_DEFAULT) {
    errorModalOpen.value = true;

    if (status !== modalsResponseNFCTypes.IS_DISABLED) {
      openSettingsButton.value = false;
    } else {
      openSettingsButton.value = true;
    }
    await handleStopReading();
  } else {
    errorModalOpen.value = false;
  }
};

const setTimeBegin = () => {
  if (order.value?.STTXT !== OrderStatus.IN_PROGRESS) {
    updateLocalOrderStatus(orderId as string, OrderStatus.IN_PROGRESS);
  }
};

const handleUrl = async (button: boolean = false) => {
  if (tagId) {
    setTimeBegin();
    router.push({
      name: NAV_OPERATIONS_TAG,
      params: { orderId, tagId },
    });
  } else if (orderId) {
    setTimeBegin();
    const orderMaintenanceOrRepair = checkOrderMaintenanceOrRepair(order.value);
    if (orderMaintenanceOrRepair) {
      router.push({
        name: NAV_MAINTENANCE_OR_REPAIR,
        params: { orderId },
      });
    } else {
      router.push({
        name: order.value?.operationsHaveVps ? NAV_OPERATIONS_MEASURE : NAV_OPERATIONS,
        params: { orderId },
      });
    }
  } else if (equipmentId.value || button) {
    const isPageCreateOrder = route.path === NAV_NFC_CREATE_ORDER;
    // Todo: Временное решение с button заменить потом equipmentIdValue на equipmentId.value.EQUNR и убрать button
    const equipmentIdValue = button
      ? isPageCreateOrder
        ? '000000000010093688'
        : '000000000010508323'
      : equipmentId.value
        ? equipmentId.value.EQUNR
        : '';

    const { equipmentsNodesByParentNodes, currentNode } =
      useGetEquipmentByParentNodes(equipmentIdValue);

    const nodes = computed(() => {
      const finalArr = [
        ...getEquipmentChildAndChildrenChild(equipmentsNodesByParentNodes.value, equipmentIdValue),
      ];
      if (currentNode.value && currentNode.value.STTXU.includes(EquipmentStatuses.DEFECTS_LEVEL)) {
        finalArr.unshift(currentNode.value);
      }
      return finalArr;
    });

    if (route.path === NAV_NFC_CREATE_DEFECT) {
      if (nodes.value.length) {
        router.push({
          name: NAV_DEFECT_CREATE_FROM_EQUIPMENT_TO_DETAIL,
          params: { equipmentId: equipmentIdValue },
        });
      } else {
        handleModalOpen(modalsResponseNFCTypes.IS_CANNOT_CREATE_DEFECT);
      }
    } else {
      const { equipmentInfo } = useGetEquipmentInfo(equipmentIdValue);

      if (equipmentInfo.value?.isNode && isPageCreateOrder) {
        handleModalOpen(modalsResponseNFCTypes.IS_CANNOT_CREATE_ORDER);
      } else {
        router.push({
          name: isPageCreateOrder ? NAV_ORDER_CREATE : NAV_EQUIPMENT_DETAIL,
          params: { equipmentId: equipmentIdValue },
        });
      }
    }
  }
};

const methodsReaderNFC = async () => {
  try {
    await handleStopReading();
    const isSupportedResponse = await nfc.isSupported();

    if (isSupportedResponse.isSupported) {
      const isEnabledResponse = await nfc.isEnabled();

      if (isEnabledResponse.isEnabled) {
        handleModalOpen(modalsResponseNFCTypes.IS_DEFAULT);
        const readTagResponse = await nfc.readTag();

        if (readTagResponse.id) {
          if (orderId) {
            const { operation } = useGetOrderByNfc(readTagResponse.id, orderId);

            if (operation.value && (!tagId || (tagId && readTagResponse.id === tagId))) {
              handleUrl();
            } else {
              handleModalOpen(modalsResponseNFCTypes.IS_NOT_MATCHES);
            }
          } else {
            equipmentId.value = equipmentList.value?.EX_EQUIP_LIST.find(
              equip =>
                equip.TIDNR === readTagResponse.id &&
                equip.STTXU?.includes(EquipmentStatuses.COST_LEVEL),
            );

            if (equipmentId.value) {
              handleUrl();
            } else {
              handleModalOpen(modalsResponseNFCTypes.IS_NOT_MATCHES_EQUIPS);
            }
          }
        } else {
          handleModalOpen(modalsResponseNFCTypes.IS_NOT_READING);
        }
      } else {
        if (statusNFC.value !== modalsResponseNFCTypes.IS_DISABLED) {
          handleModalOpen(modalsResponseNFCTypes.IS_DISABLED);
        }
      }
    } else {
      isSupportedNFC.value = false;
      handleModalOpen(modalsResponseNFCTypes.IS_NOT_SUPPORTING);
    }
  } catch {
    console.log('methodsReaderNFC');
  }
};

const isShowButtonNoTag = [NAV_NFC, NAV_NFC_CREATE_DEFECT].includes(route.path);

const visibilityChangeFunc = async () => {
  if (document.visibilityState === 'visible') {
    const isEnabledResponse = await nfc.isEnabled();
    if (!isEnabledResponse.isEnabled) {
      handleModalOpen(modalsResponseNFCTypes.IS_DISABLED);
    }
  }
};

onMounted(() => {
  methodsReaderNFC();
  document.addEventListener('visibilitychange', visibilityChangeFunc);
});

onUnmounted(async () => {
  await handleStopReading();
  document.removeEventListener('visibilitychange', visibilityChangeFunc);
});
</script>

<template>
  <div
    :class="{
      [$style.page]: true,
      [$style.blue]: userIsRepairer,
      [$style.purple]: userIsSupervisor,
    }"
  >
    <PageHeading :text="titlePage" :description="pageDescription" :transparent="true" />

    <div :class="$style.wrap">
      <a-button
        size="large"
        :class="$style.back"
        v-if="isShowButtonNoTag"
        @click="handleButtonNoTag"
      >
        Метка отсутствует
      </a-button>
      <NfcIcon @click="isInsideMobileApp ? null : handleUrl(true)" :class="$style.icon" />
      <a-typography-title :level="5" :class="$style.title" v-html="textPage" />
    </div>
  </div>
  <InformationDialog
    v-if="errorModalOpen"
    :title="titleModal"
    :text="textModal"
    :onClose="handleModalClose"
    :isButtonLink="isButtonLink"
  >
    <a-button
      v-if="openSettingsButton"
      type="link"
      size="large"
      :class="$style.settings"
      @click="handleSettings"
      >Настройки</a-button
    >
    <a-button v-if="isButtonLink" type="primary" size="large" @click="handleCancelOrder"
      >Да</a-button
    >
  </InformationDialog>
</template>

<style lang="scss" module>
.page {
  height: calc(100vh - 114px);
  display: flex;
  flex-direction: column;
  padding-bottom: 24px;
  background-image: url('../../../../shared/assets/img/nfc-orange.png');
  background-position: center 35%;
  background-size: auto 273px;
  background-repeat: no-repeat;
}

.blue {
  background-image: url('../../../../shared/assets/img/nfc-blue.png');
}

.purple {
  background-image: url('../../../../shared/assets/img/nfc-purple.png');
}

.wrap {
  margin-top: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: var(--color-primary, #ef7f1a);
  .icon {
    width: 44px;
    height: 44px;
    color: var(--color-primary, #ef7f1a);
  }
  .title {
    margin: 0;
    max-width: 240px;
    text-align: center;
  }

  .back {
    font-size: 18px;
    margin: 0 0 24px;
  }
}

.settings {
  color: var(--color-primary, #ef7f1a);
}
</style>
